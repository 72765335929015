import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import useMediaQuery from 'hooks/useMediaQuery';

const isPage = (index, actual) => actual === index + 1;

const getTotalOfPages = (el) => {
  const carouselWidth = el?.scrollWidth;
  const pageWidth = el.clientWidth;

  return Math.ceil(carouselWidth / pageWidth) || 0;
};

const Dots = ({ carouselRef, className }) => {
  const { isMd } = useMediaQuery();
  const [actualPage, setActualPage] = useState(1);

  useEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    let prevScrollLeft = 0;

    const updateActualPage = (event) => {
      const currScrollLeft = event.target.scrollLeft;
      const isScrollingToRight = currScrollLeft - prevScrollLeft > 0;
      const maxScrollX = event.target?.scrollWidth - event.target.clientWidth;

      const totalOfPages = getTotalOfPages(carouselRef.current);

      const pageSize = maxScrollX / totalOfPages;
      /**
       * The carousel auto-scroll doesn't have the same behavior in desktop when
       * scrolling right-to-left with left-to-right so, to simplify the
       * calculations to get `actualPage`, we sum scroll left with `pageSize`
       * as a threshold when scrolling right-to-left.
       */
      const threshold = isMd ? (isScrollingToRight ? 0 : pageSize) : 0;
      const scrollWidth = currScrollLeft + threshold;
      const currPage = Math.min(
        Math.trunc(scrollWidth / pageSize) + 1,
        totalOfPages
      );

      prevScrollLeft = currScrollLeft;
      setActualPage(currPage);
    };

    carouselRef.current.addEventListener('scroll', updateActualPage);

    return () => {
      carouselRef.current?.removeEventListener('scroll', updateActualPage);
    };
  }, [carouselRef.current]);

  const totalOfPages = useMemo(() => {
    if (!carouselRef.current) {
      return 0;
    }

    return getTotalOfPages(carouselRef.current);
  }, [isMd, carouselRef.current]);

  return (
    <div
      className={twJoin(
        'flex w-full items-center justify-center gap-x-2',
        className
      )}
    >
      {Array(totalOfPages)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={twJoin(
              'size-2 rounded-full',
              isPage(i, actualPage)
                ? 'bg-neutral-low-400 dark:bg-neutral-high-200'
                : 'bg-neutral-high-300 dark:bg-neutral-low-300'
            )}
          />
        ))}
    </div>
  );
};

Dots.propTypes = {
  carouselRef: PropTypes.shape().isRequired,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Dots;
